.info-box {
    box-sizing: border-box;
  .info-content {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .info-tabs-content {
          width: 890px;
          background-color: #FFF;
          padding-bottom: 37px;
          margin-bottom: 20px;
          box-shadow: 0 5px 10px 1px rgba(32, 32, 32, 0.05);
          position: relative;
          ::v-deep .el-scrollbar__wrap {
              overflow-x: hidden;
          }
          .project-info-body, .project-extend {
              width: 100%;
              //height: calc(100% - 75px);
          }
          .project-extend {
              height: calc(100vh - 176px);
          }
          .tabs-box {
              width: calc(100% - 48px);
              padding: 0 24px;
              margin-bottom: 16px;
              height: 55px;
              border-bottom: 1px solid #EEEEEE;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .tab-item-group {
                  height: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .tab-item {
                      height: 100%;
                      line-height: 55px;
                      font-size: 18px;
                      font-weight: 400;
                      color: #999999;
                      margin: 0 20px;
                      cursor: pointer;
                      &.active {
                          color: #409EFF;
                          position: relative;
                          &::before {
                              content: '';
                              height: 3px;
                              width: 100%;
                              background-color: #409EFF;
                              position: absolute;
                              bottom: -1px;
                          }
                      }
                  }
              }
          }
          .project-header-main {
              margin-bottom: 53px;
              width: 100%;
              height: 416px;
              position: relative;
              .project-header-bg {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: url("~@/assets/images/project/project_bg.png") no-repeat;
                  background-size: 100% 100%;
                  z-index: 1;
              }
              .project-header-content {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 2;
                  .header-title {
                      width: 100%;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin: 39px 0 24px;
                      h1 {
                          color: #FFFFFF;
                      }
                  }
              }
          }
      }
  }
}
.header-duration-box {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.medium-size {
        height: 56px;
        .bg-ellipse-box {
            height: 100%;
            border-radius: 28px;
            .bg-ellipse-dot {
                width: 13px;
                height: 13px;
                border-radius: 7px;
                top: 21.5px;
            }
        }
    }
    .bg-ellipse-box {
        height: 100%;
        border-radius: 25px;
        background: #F2A932;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 25px;
        &.blue-b {
            background: linear-gradient(0deg, #597CF3 0%, #698DFF 100%);
        }
        .bg-ellipse-dot {
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background: #F1F5FF;
            top: 19px;
            &.dot-left {
                left: 19px;
            }
            &.dot-right {
                right: 19px;
            }
        }
        .ellipse-text {
            width: 266px;
            height: 100%;
            text-align: center;
            line-height: 50px;
            color: #FFFFFF;
            font-size: 24px;
            font-weight: 400;
            &.auto-width {
                width: inherit;
                line-height: 56px;
                text-align: center;
                padding: 0 16.5px;
            }
        }
    }
}
.info-sub-item {
    width: 100%;
    margin-top: 25px;
    padding: 0 30px;
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    .sub-item-box {
        width: 100%;
        position: relative;
        border-radius: 25px;
        background: #FFFFFF;
        border: 3px solid #5C7FF5;
        box-shadow: 0 13px 0 0 #9EB9E6;
        margin-top: 53px;
        .sub-item-title-box {
            width: 100%;
            position: absolute;
            top: -31px;
        }
        .sub-item-content {
            ::v-deep .el-table td,::v-deep .el-table th.is-leaf {
                border-right: 2px solid #FFFFFF;
                &.last-th {
                    border-right: none;
                }
            }
            .text-content {
                padding: 52px 32px 32px;
                p {
                    margin: 0;
                    padding: 0;
                }
            }
            ::v-deep .eval-header {
                tr, th {
                    background: #D3DDFF;
                }
            }
            ::v-deep .el-table tr {
                background: #F0F3FF;
            }
            ::v-deep .el-table__row--striped td {
                background: #D3DDFF;
            }
            .sucai-links-content {
                margin: 70px 80px 56px;
                width: calc(100% - 160px);
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                a {
                    color: #333333;
                }
                .sucai-link-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 20px;
                    &:nth-child(2n) {
                        margin-left: 60px;
                    }
                    .sucai-icon, .download-btn {
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        text-align: center;
                        line-height: 22px;
                        background: #409EFF;
                        border-radius: 2px;
                        color: #FFFFFF;
                    }
                    span.file-name {
                        margin: 0 20px 0 10px;
                        width: 200px;
                        height: 20px;
                        font-weight: 400;
                        color: #333333;
                        font-size: 18px;
                        line-height: 18px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                }
            }
            .app-list-box {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 52px 24px 28px;
                .app-item {
                    width: 140px;
                    height: 113px;
                    background: #FFFFFF;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    &:hover {
                        box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                    }
                    .app-cover {
                        width: 64px;
                        height: 64px;
                        overflow: hidden;
                        border-radius: 24px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .app-name {
                        width: 100%;
                        height: 13px;
                        font-size: 12px;
                        line-height: 13px;
                        margin-top: 16px;
                        color: #333333;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.info-fix-links {
    width: 300px;
    height: calc(100vh - 80px);
    padding: 21px 28px;
    margin-bottom: 20px;
    margin-left: 16px;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 1px rgba(32, 32, 32, 0.05);
    .fix-links-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 22px;
        padding-left: 30px;
        margin-bottom: 34px;
        .links-head-txt {
            height: 100%;
            color: #333333;
            font-size: 18px;
            font-weight: bold;
            font-family: "Microsoft YaHei", serif;
            position: relative;
            span {
                display: inline-block;
                width: 75px;
                height: 18px;
                font-size: 18px;
                line-height: 18px;
                z-index: 2;
                position: absolute;
                bottom: 0px;
                left: 0px;
            }
            .bottom-link {
                content: '';
                background: #A3B9FE;
                width: 77px;
                height: 6px;
                position: absolute;
                bottom: -3px;
                left: -2px;
                z-index: 1;
            }
        }
    }
    .fix-links-lists {
        height: calc(100% - 56px);
        width: calc(100% - 15px);
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .link-item-box {
            margin-bottom: 33px;
            .no-link-item-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: center;
                .no-link {
                    width: 191px;
                    margin: 0 auto;
                }
                .no-link-title {
                    margin-top: 25px;
                }
            }
            .link-item-content {
                width: 100%;
                height: 32px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                .link-icon {
                    width: 32px;
                    height: 32px;
                    margin-right: 11px;
                }
                .link-title {
                    width: 214px;
                    height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 16px;
                    font-family: "Microsoft YaHei", serif;
                    line-height: 16px;
                }
            }
        }
    }
}
.link-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 11px;
    background-size: 32px 32px;
    &.coureware {
        background-image: url("~@/assets/images/courseware.png");
    }
    &.peitao {
        background-image: url("~@/assets/images/peitao.png");
    }
    &.person-resource {
        background-image: url("~@/assets/images/person-resource.png");
    }
    &.textbook {
        background-image: url("~@/assets/images/textbook.png");
    }
    &.training-center {
        background-image: url("~@/assets/images/training-center.png");
    }
    &.test-center {
        background-image: url("~@/assets/images/test-center.png");
    }
    &.micro-lesson {
        background-image: url("~@/assets/images/micro-lesson.png");
    }
    &.animation-link {
        background-image: url("~@/assets/images/animation.png");
    }
}
.app-dialog-content {
    box-sizing: border-box;
    width: 100%;
    height: 353px;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    * {
        box-sizing: border-box;
    }
    .dialog-app-name {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #EEEEEE;
        span {
            display: inline-block;
            color: #333333;
            font-size: 24px;
            height: 23px;
            line-height: 23px;
            margin-bottom: 20px;
        }
    }
    .dialog-app-info {
        padding: 30px;
    }
}
.dialog-app {
    .dialog-app-batons {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            display: inline-block;
            margin-right: 20px;
        }
    }
}
.link-dialog-content {
    width: 100%;
    video {
        width: 100%;
    }
}